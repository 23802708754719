<template>
  <v-container class="plans-overview general">
    <PageTitle :title="'Wallet'" />
    <div class="sw-mb-7 sw-px-2">
      <v-layout row wrap class="align-end">
        <v-flex xs6>
          <div class="sw-mb-1 sw-h5">Your wallet</div>
          <div class="sw-h5 font-weight-light">
            Account wallet contains: {{ balance }} {{ defaultCurrency }}
          </div>
        </v-flex>
        <v-flex xs6 class="text-right">
          <v-btn
            round
            large
            class="ma-0 sw-secondary-bg sw-on-secondary text-none"
            :disabled="!paymentMethod.id"
            @click="topUpWalletDialog = true"
            >Top up wallet</v-btn
          >
        </v-flex>
      </v-layout>
    </div>
    <template v-if="currentUserPlan && currentUserPlan.id">
      <PageTitle :title="'Subscription'" />
      <div class="sw-mb-7 sw-px-2">
        <v-layout row class="sw-mb-2 align-end">
          <v-flex xs8>
            <div class="sw-mb-1 sw-h4 font-weight-light black--text">
              {{ currentUserPlan.name }}
              <span class="sw-pl-1 sw-body-2">{{
                currentUserPlan.description
              }}</span>
            </div>
          </v-flex>
          <v-flex xs4 class="text-right">
            <v-btn
              round
              large
              class="ma-0 sw-secondary-bg sw-on-secondary text-none"
              @click="$router.push({ name: 'plans' })"
              >Change plan</v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 class="sw-pr-3">
            <div
              v-for="(feature, i) in currentUserPlan.features"
              :key="`feature-${i}`"
              class="sw-mb-1 sw-caption"
            >
              <font-awesome-icon
                icon="check-circle"
                color="green"
                class="sw-mr-1 sw-body-1"
              />
              <span class="sw-body-1 font-weight-light black--text">{{
                feature
              }}</span>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </template>
    <PageTitle :title="'Billing history'" />
    <div class="sw-mb-7 sw-px-2">
      <v-layout row wrap class="sw-mb-1">
        <v-flex xs2 class="sw-accent font-weight-medium">
          Date
        </v-flex>
        <v-flex xs2 class="sw-accent font-weight-medium">
          Type
        </v-flex>
        <v-flex xs5 class="sw-accent font-weight-medium">
          Order number
        </v-flex>
        <v-flex xs2 class="sw-accent font-weight-medium">
          Amount
        </v-flex>
      </v-layout>
      <div v-for="(invoice, i) in invoices" :key="`invoice-${i}`">
        <v-layout row wrap class="align-center sw-py-1">
          <v-flex xs2>
            {{ invoice.date }}
          </v-flex>
          <v-flex xs2>
            Invoice
          </v-flex>
          <v-flex xs5>
            {{ invoice.id }}
          </v-flex>
          <v-flex xs2>
            {{ invoice.total }}
          </v-flex>
          <v-flex xs1 class="text-right">
            <v-btn
              icon
              class="ma-0"
              :loading="isVuexLoading(invoice.id)"
              @click="downloadInvoice(invoice.id)"
            >
              <font-awesome-icon class="sw-accent" icon="file-pdf" />
            </v-btn>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </div>
    </div>
    <PageTitle :title="'Card details & Billing information'" />
    <div class="sw-mb-7 sw-px-2">
      <v-layout row wrap class="sw-mb-3">
        <v-flex xs12>
          <div class="sw-mb-1 sw-h5">Card details</div>
        </v-flex>
        <v-flex v-if="paymentMethod.card" xs12 class="sw-h5 font-weight-light">
          <div v-if="currentUserPlan && currentUserPlan.id">
            {{ currentUserPlan.price / 100 }}
            {{ currentUserPlan.price_currency }}/month
          </div>
          <div>Monthly payment plan</div>
          <div>
            <span class="text-capitalize">{{ paymentMethod.card.brand }}</span
            >, ending **** {{ paymentMethod.card.last4 }}
          </div>
        </v-flex>
        <v-flex v-if="!isLoading && !paymentMethod.card" xs12>
          <div class="sw-accent sw-h5 font-weight-light">No card added</div>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="align-end">
        <v-flex xs6>
          <div class="sw-mb-1 sw-h5">Billing information</div>
          <div class="sw-h5 font-weight-light">
            <div v-if="paymentAddress.first_name && paymentAddress.last_name">
              {{ paymentAddress.first_name }} {{ paymentAddress.last_name }}
            </div>
            <div v-if="paymentAddress.organization">
              {{ paymentAddress.organization }}
            </div>
            <div v-if="paymentAddress.street">
              {{ paymentAddress.street }}
            </div>
            <div v-if="paymentAddress.state">
              {{ paymentAddress.state }}
            </div>
            <div v-if="paymentAddress.postal_code">
              {{ paymentAddress.postal_code }}
            </div>
            <div v-if="paymentAddress.vat_number">
              CVR / VAT ID: {{ paymentAddress.vat_number }}
            </div>
          </div>
        </v-flex>
        <v-flex xs6 class="text-right">
          <v-btn
            round
            large
            class="ma-0 sw-secondary-bg sw-on-secondary text-none"
            @click="paymentDetails = true"
            >Administrate</v-btn
          ></v-flex
        >
      </v-layout>
    </div>
    <PaymentDetails
      v-model="paymentDetails"
      @method-created="listPaymentMethods"
      @method-deleted="paymentMethod = {}"
      @address-created="listAddresses"
      @address-updated="listAddresses"
    />
    <TopUpWallet v-model="topUpWalletDialog" @success="getBalance" />
  </v-container>
</template>

<script>
import plans from "@/components/Plans/plans.json";
import PaymentDetails from "@/components/Stripe/PaymentDetails.vue";
import TopUpWallet from "@/components/Stripe/TopUpWallet.vue";
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  data: () => ({
    isLoading: false,
    plans: [],
    paymentAddress: {},
    paymentMethod: {},
    balance: 0,
    invoices: [],
    paymentDetails: false,
    topUpWalletDialog: false,
  }),
  computed: {
    defaultCurrency() {
      return process.env.VUE_APP_DEFAULT_CURRENCY;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentUserPlan() {
      if (!this.currentUser.plan) return {};

      return {
        ...this.currentUser.plan,
        ...plans.find((el) => el.slug === this.currentUser.plan.slug),
      };
    },
  },
  components: {
    PaymentDetails,
    TopUpWallet,
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getBalance();
        await this.listInvoices();
        await this.listAddresses();
        await this.listPaymentMethods();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("common.server_error"),
          });
          this.isLoading = false;
        }
      }
    },
    async getBalance() {
      const params = {
        currency: this.defaultCurrency,
      };

      const response = await this.$api.auth.balance(params);

      this.balance = Number(response.data.data.balance) / 100 || 0;
    },
    async listInvoices() {
      const response = await this.$api.authStripe.invoices();

      this.invoices = response.data.data;
    },
    async downloadInvoice(invoiceId) {
      try {
        this.startVuexLoading(invoiceId);

        const response = await axios({
          url:
            process.env.VUE_APP_API_URL +
            `auth/stripe/invoices/${invoiceId}/download`,
          method: "GET",
          responseType: "blob",
        });

        this.endVuexLoading(invoiceId);

        fileDownload(response.data, `Invoice-${invoiceId}.pdf`);
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("common.server_error"),
          });
          this.endVuexLoading(invoiceId);
        }
      }
    },
    async listAddresses() {
      const response = await this.$api.authAddresses.list();

      if (!response.data.data) return;

      this.paymentAddress = response.data.data[0];
    },
    async listPaymentMethods() {
      const response = await this.$api.authStripe.list();

      if (!response.data.data || !response.data.data[0]) {
        return;
      }

      this.paymentMethod = response.data.data[0];
    },
  },
};
</script>
