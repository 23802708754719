<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    content-class="content"
    class="top-up"
    attach
    persistent
  >
    <v-card class="sw-py-3 sw-px-6">
      <v-btn
        flat
        icon
        class="ma-0 sw-accent-bg sw-on-accent close"
        @click="dialog = false"
      >
        <v-icon small>close</v-icon>
      </v-btn>
      <v-layout row wrap class="sw-mb-3">
        <v-flex xs12 class="sw-mb-3 sw-h5 text-center"
          >Top up your wallet</v-flex
        >
        <v-flex xs12 :class="{ 'is-loading': isLoading }">
          <v-layout row wrap>
            <v-flex xs12 class="sw-mb-1 sw-caption sw-font-weight-light">
              Current account value: {{ balance }} {{ defaultCurrency }}
            </v-flex>
            <v-flex xs12>
              <v-form data-vv-scope="deposit">
                <v-text-field
                  v-model="amount"
                  v-validate.disable="'required|min_value:1'"
                  data-vv-name="amount"
                  :data-vv-as="'Amount'"
                  mask="#####"
                  :placeholder="'Type amount, or choose below'"
                  hide-details
                  solo
                ></v-text-field>
                <ErrorMessages
                  :error-messages="errors.collect('deposit.amount')"
                />
              </v-form>
            </v-flex>
            <v-flex xs12>
              <v-layout row class="justify-space-between">
                <v-btn
                  small
                  depressed
                  class="mx-0 text-none"
                  @click="amount = 50"
                  >50 {{ defaultCurrency }}</v-btn
                >
                <v-btn
                  small
                  depressed
                  class="mx-0 text-none"
                  @click="amount = 80"
                  >80 {{ defaultCurrency }}</v-btn
                >
                <v-btn
                  small
                  depressed
                  class="mx-0 text-none"
                  @click="amount = 100"
                  >100 {{ defaultCurrency }}</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <div class="text-center">
        <v-btn
          round
          large
          class="sw-accent-bg sw-on-accent text-none"
          :loading="isLoading"
          @click="deposit"
          >Top up</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    balance: 0,
    amount: null,
  }),
  computed: {
    defaultCurrency() {
      return process.env.VUE_APP_DEFAULT_CURRENCY;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.amount = null;
        this.getBalance();
      } else {
        this.errors.clear();
      }
    },
  },
  methods: {
    async getBalance() {
      try {
        this.isLoading = true;

        const params = {
          currency: this.defaultCurrency,
        };

        const response = await this.$api.auth.balance(params);

        this.isLoading = false;

        this.balance = parseInt(response.data.data.balance, 10) / 100 || 0;
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("common.server_error"),
          });
          this.isLoading = false;
        }
      }
    },
    async deposit() {
      const isValid = await this.$validator.validateAll("deposit");

      if (!isValid) return;

      try {
        this.isLoading = true;

        const params = {
          amount: parseInt(this.amount, 10) * 100,
          currency: this.defaultCurrency,
        };

        await this.$api.authStripe.deposit(params);

        this.isLoading = false;

        this.$emit("success");

        this.dialog = false;
      } catch (error) {
        if (error) {
          this.$store.dispatch("addErrorNotification", {
            message: this.$t("common.server_error"),
          });
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.top-up {
  .content {
    border-radius: 19px !important;
  }

  .v-dialog {
    overflow: visible !important;

    .close {
      width: 32px;
      height: 32px;
      position: absolute;
      top: -14px;
      right: -14px;
    }
  }

  .v-card {
    border-radius: 19px !important;

    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 36px !important;
    }

    .v-input__slot {
      border-radius: 5px !important;
      background-color: #f2f2f2 !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
}
</style>
